@import "~antd/dist/antd.css";
@import "../src/styles/timepicker.css";
@import "~nes.css/css/nes.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "apple-pro-2";
  src: url("./fonts/Apple II Pro.otf") format("opentype");
  font-weight: 300;
} */

@font-face {
  font-family: "perfect-DOS";
  src: url("./fonts/Perfect DOS VGA 437.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Webpixel-bitmap";
  src: url("./fonts/webpixel-bitmap.light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Webpixel-bitmap";
  src: url("./fonts/webpixel-bitmap.medium.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Webpixel-bitmap";
  src: url("./fonts/webpixel-bitmap.regular.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Webpixel-bitmap";
  src: url("./fonts/webpixel-bitmap.bold.otf") format("opentype");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "perfect-DOS", "Webpixel-bitmap", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

/* GLOBAL */

h2 {
  font-weight: 400;
  color: #676868;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.logo h3 {
  color: white;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-card {
  border-radius: 20px;
  box-shadow: 0 22px 25px 0 rgba(228, 228, 234, 0.22),
    0 9px 23px 0 rgba(228, 228, 234, 0.5);

  /* background: linear-gradient(145deg, #ececec, #ffffff);
 
  box-shadow: -2px -2px 5px rgba(255, 255, 255, 1),
  3px 3px 5px rgba(0, 0, 0, 0.1); */
}

.ant-card-head-title {
  font-weight: 400;
  color: #535454;
}

.ant-layout {
  background: #ffffff;
}

.ant-layout-sider {
  box-shadow: 0 12px 15px 0 rgb(13 13 28 / 34%),
    0 9px 13px 0 rgba(228, 228, 234, 0.5);
}

.ant-layout-sider-children {
  overflow: auto;
  height: 100vh;
  top: 0;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6def8;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #000;
  font-weight: 500;
}

.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #000;
  font-weight: 700;
}

.ant-menu-item-selected {
  margin: 20px;
  border-radius: 10px;
}

.popular-threads {
  height: 650px;
  overflow: auto;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 3px rgba(158, 147, 147, 0.25);
  border-radius: 10px;
  padding: 20px;
}

.popular-channels {
  height: 350px;
  overflow: auto;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 3px rgba(158, 147, 147, 0.25);
  border-radius: 10px;
  padding: 20px;
}

.popular-thread-replies {
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  background: #f1ecfa;
  margin-left: 10px;
  font-size: 1rem;
  color: #393939;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: anywhere;
}

.ant-dropdown-menu-item
  > .ant-checkbox-wrapper
  > .ant-checkbox
  > .ant-checkbox-inner {
  display: none;
}

.respond-css:hover {
  background: #61dafb;
}

.ant-select > .ant-select-selector {
  border-radius: 20px;
}

.add-slack:hover {
  box-shadow: inset 0px 3px 3px rgba(158, 147, 147, 0.25);
}
.message_actions:hover {
  transform: scale(1.2);
}

input[type="text"] {
  border-radius: 5px;
}

textarea.ant-input {
  border-radius: 5px;
}

.ant-btn {
  border-radius: 5px;
}

.ant-select-selector {
  border-radius: 5px;
}

.ant-modal-header,
.ant-modal-footer,
.ant-modal-content,
.ant-dropdown-menu,
.ant-popover {
  border-radius: 5px;
}

.fit-width {
  max-width: 100%;
  max-height: 100%;
  display: block; /* remove extra space below image */
}

.ant-tabs-tab {
  font-size: 20px;
}
input[type="password"] {
  font-family: "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: normal;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

/* .nes-input {
  padding: 0.5rem 0.5rem;
  border-image-width: 1;
} */

/* @font-face {
  font-family: 'Calibre';
  src: url('fonts/Calibre-Light.otf') format("opentype");
  src: url('fonts/Calibre-Medium.otf') format("opentype");
  src: url('fonts/Calibre-Regular.otf') format("opentype");
} */

/* ./src/index.css */

/* @font-face {
  font-family: "Calibre";
  src: url("fonts/calibre-web-light.woff") format("woff"),
    url("fonts/calibre-web-light.woff2"), format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Calibre";
  src: url("fonts/calibre-web-regular.woff") format("woff"),
    url("fonts/calibre-web-regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Calibre";
  src: url("fonts/calibre-web-medium.woff") format("woff"),
    url("fonts/calibre-web-medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Calibre";
  src: url("fonts/calibre-web-semibold.woff") format("woff"),
    url("fonts/calibre-web-semibold.woff2") format("woff2");
  font-weight: 600;
} */
